var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{staticClass:"justify-content-center"},[_c('h1',[_vm._v("Shop Settings")]),_c('b-col',{attrs:{"md":"12"}},[_c('VeeObserver',{ref:"form",attrs:{"slim":""}},[_c('b-form-group',{attrs:{"label":"Address"}},[_c('VeeProvider',{attrs:{"name":"addressLine1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Address Line 1","addon-left-icon":"fa fa-address-card","error":errors[0]},model:{value:(_vm.payload.addressLine1),callback:function ($$v) {_vm.$set(_vm.payload, "addressLine1", $$v)},expression:"payload.addressLine1"}})]}}])}),_c('VeeProvider',{attrs:{"name":"addressLine2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Address Line 2","addon-left-icon":"fa fa-address-card","error":errors[0]},model:{value:(_vm.payload.addressLine2),callback:function ($$v) {_vm.$set(_vm.payload, "addressLine2", $$v)},expression:"payload.addressLine2"}})]}}])}),_c('VeeProvider',{attrs:{"name":"addressLine3","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Address Line 3","addon-left-icon":"fa fa-address-card","error":errors[0]},model:{value:(_vm.payload.addressLine3),callback:function ($$v) {_vm.$set(_vm.payload, "addressLine3", $$v)},expression:"payload.addressLine3"}})]}}])}),_c('VeeProvider',{attrs:{"name":"postcode","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Postcode","addon-left-icon":"fa fa-map-pin","error":errors[0]},model:{value:(_vm.payload.postcode),callback:function ($$v) {_vm.$set(_vm.payload, "postcode", $$v)},expression:"payload.postcode"}})]}}])}),_c('VeeProvider',{attrs:{"name":"town","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Town","addon-left-icon":"fa fa-map","error":errors[0]},model:{value:(_vm.payload.town),callback:function ($$v) {_vm.$set(_vm.payload, "town", $$v)},expression:"payload.town"}})]}}])}),_c('VeeProvider',{attrs:{"name":"country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Country","addon-left-icon":"fa fa-globe","error":errors[0]},model:{value:(_vm.payload.country),callback:function ($$v) {_vm.$set(_vm.payload, "country", $$v)},expression:"payload.country"}})]}}])})],1),_c('b-form-group',{attrs:{"label":"Contact"}},[_c('VeeProvider',{attrs:{"name":"contact1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"Contact 1","addon-left-icon":"fa fa-phone","error":errors[0]},model:{value:(_vm.payload.contact1),callback:function ($$v) {_vm.$set(_vm.payload, "contact1", $$v)},expression:"payload.contact1"}})]}}])}),_c('VeeProvider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('base-input',{staticClass:"input-group-alternative",attrs:{"placeholder":"E-mail","addon-left-icon":"fa fa-at","error":errors[0]},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}})]}}])})],1),_c('div',{staticClass:"text-center"},[_c('base-button',{staticClass:"my-4",attrs:{"variant":"primary","type":"submit","loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_c('i',{staticClass:"fa fa-save"}),_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }