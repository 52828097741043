<template>
  <b-row class="justify-content-center">
    <h1>Shop Settings</h1>
    <b-col md="12">
      <VeeObserver ref="form" slim>
        <b-form-group label="Address">
          <VeeProvider name="addressLine1" rules="required" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="Address Line 1"
              v-model="payload.addressLine1"
              addon-left-icon="fa fa-address-card"
              :error="errors[0]"
            />
          </VeeProvider>
          <VeeProvider name="addressLine2" rules="required" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="Address Line 2"
              addon-left-icon="fa fa-address-card"
              v-model="payload.addressLine2"
              :error="errors[0]"
            />
          </VeeProvider>
          <VeeProvider name="addressLine3" rules="" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="Address Line 3"
              addon-left-icon="fa fa-address-card"
              v-model="payload.addressLine3"
              :error="errors[0]"
            />
          </VeeProvider>
          <VeeProvider name="postcode" rules="required" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="Postcode"
              addon-left-icon="fa fa-map-pin"
              v-model="payload.postcode"
              :error="errors[0]"
            />
          </VeeProvider>
          <VeeProvider name="town" rules="required" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="Town"
              addon-left-icon="fa fa-map"
              v-model="payload.town"
              :error="errors[0]"
            />
          </VeeProvider>
          <VeeProvider name="country" rules="required" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="Country"
              v-model="payload.country"
              addon-left-icon="fa fa-globe"
              :error="errors[0]"
            />
          </VeeProvider>
        </b-form-group>
        <b-form-group label="Contact">
          <VeeProvider name="contact1" rules="required" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="Contact 1"
              addon-left-icon="fa fa-phone"
              v-model="payload.contact1"
              :error="errors[0]"
            />
          </VeeProvider>
          <VeeProvider name="email" rules="required|email" v-slot="{ errors }">
            <base-input
              class="input-group-alternative"
              placeholder="E-mail"
              addon-left-icon="fa fa-at"
              v-model="payload.email"
              :error="errors[0]"
            />
          </VeeProvider>
        </b-form-group>
        <div class="text-center">
          <base-button
            variant="primary"
            type="submit"
            class="my-4"
            @click="save()"
            :loading="loading"
          >
            <i class="fa fa-save"></i> Save
          </base-button>
        </div>
      </VeeObserver>
    </b-col>
  </b-row>
</template>

<script>
import ShopSettingService from "features/settings/services/ShopSettingService";

import { removeButtons } from "helpers";

export default {
  name: "FormUsers",
  data: () => ({
    loading: false,
    payload: {
      addressLine1: "",
      addressLine2: "",
      addressLine3: "",
      postcode: "",
      town: "",
      country: "",
      contact1: "",
      contact2: "",
      email: "",
    },
    removeButtons,
  }),
  computed: {
    isEdit() {
      return this.$route.name === "users-edit";
    },
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      try {
        this.loading = true;
        const { data } = await ShopSettingService.show();
        if (data) {
          this.payload.addressLine1 = data.address_line_1;
          this.payload.addressLine2 = data.address_line_2;
          this.payload.addressLine3 = data.address_line_3;
          this.payload.postcode = data.postcode;
          this.payload.town = data.town;
          this.payload.country = data.country;
          this.payload.contact1 = data.contact_1;
          this.payload.contact2 = data.contact_2;
          this.payload.email = data.email;
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Failed to get registration data",
        });
      } finally {
        this.loading = false;
      }
    },
    async save() {
      try {
        if (await this.$refs.form.validate()) {
          this.loading = true;
          const {
            addressLine1,
            addressLine2,
            addressLine3,
            postcode,
            town,
            country,
            contact1,
            contact2,
            email,
          } = this.payload;
          const payload = {
            addressLine1,
            addressLine2,
            addressLine3,
            postcode,
            town,
            country,
            contact1,
            contact2,
            email,
          };
          await ShopSettingService.save(payload);
          this.$swal({
            icon: "success",
            title: "Registration performed successfully",
          }).then(() => this.$router.push("/settings/shop"));
        }
      } catch (e) {
        this.$swal({
          icon: "error",
          title: "Trademark registration failed",
          text: "Check the data entered and try again",
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
